import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';

interface EmailPreviewModalProps {
  open: boolean;
  onClose: () => void;
  dataPreviewEmail: any;
}

const EmailPreviewModal: React.FC<EmailPreviewModalProps> = ({
  open,
  onClose,
  dataPreviewEmail,
}) => {
  const emailPreview = dataPreviewEmail?.getListSendMailPreview.emailJobs;

  const invoiceTemplate = (emailPreview: any) => {
    return <DialogContent>
      <Typography variant="body1">
        <strong>From:</strong> {emailPreview?.companyName}
        <br />
        <strong>Subject:</strong> {emailPreview?.subject}
        <br />
        <strong>To:</strong> {emailPreview?.to}
        <br />
        {emailPreview?.attachments.length > 0 && <strong>Attachment:</strong>} {
          emailPreview?.attachments?.map((name: string) => {
            return <>📎{name}{' '}</>;
          })
        }
        <br />
        ===================================
        <br />
        {emailPreview?.companyName}
        <br />
        担当者様
        <br />
        <br />
        平素よりお世話になっております。
        <br />
        株式会社ギフトモール Annyお祝い体験事務局でございます。
        <br />
        <br />

        掲題の件についてのご連絡です。
        <br />
        <br />
        【Annyお祝い体験】{emailPreview?.month}月度ご利用に関する請求書をお送り致します。
        <br />
        内容をご確認頂けますでしょうか。
        <br />
        <br />

        ご不明点等ございましたらお気軽にお申し付けください。
        <br />
        <br />

        どうぞよろしくお願いいたします。

        <br />
        ===================================
        <br />
        最高の"一生に一度”をあなたに
        <br />
        Annyお祝い体験 | パートナー窓口
        <br />
        https://oiwai.anny.gift/
        <br />
        東京都中央区八重洲一丁目4番10号
        <br />
        東京建物八重洲仲通りビル12F
        <br />
        平日 10:00~17:00
        <br />
      </Typography>
    </DialogContent>
  }

  const paymentNotice = (emailPreview: any) => {
    return <DialogContent>
      <Typography variant="body1">
        <strong>From:</strong> {emailPreview?.companyName}
        <br />
        <strong>Subject:</strong> {emailPreview?.subject}
        <br />
        <strong>To:</strong> {emailPreview?.to}
        <br />
        {emailPreview?.attachments.length > 0 && <strong>Attachment:</strong>} {
          emailPreview?.attachments?.map((name: string) => {
            return <>📎{name}{' '}</>;
          })
        }
        <br />
        ===================================
        <br />
        {emailPreview?.companyName}
        <br />
        ご担当者様
        <br />
        <br />
        平素よりお世話になっております。
        <br />
        株式会社ギフトモール Annyお祝い体験事務局でございます。
        <br />
        <br />

        掲題の件についてのご連絡です。
        <br />
        <br />
        【Annyお祝い体験】{emailPreview?.month}月度ご利用に関するお支払通知書とご請求書をお送り致します。
        内容をご確認頂けますでしょうか。
        <br />
        <br />
        <p style={{ 'textDecoration': 'line-through', 'margin': 0 }}>*別に「ご請求書」をお送りしておりますので、弊社へのお振込み額はこちらをご確認いただきますようよろしくお願いいたします。</p>
        <br />
        ご不明点等ございましたらお気軽にお申し付けください。
        <br />
        <br />
        どうぞよろしくお願いいたします。
        <br />
        ===================================
        <br />
        最高の"一生に一度”をあなたに
        <br />
        Annyお祝い体験｜パートナー窓口
        <br />
        https://oiwai.anny.gift/
        <br />
        東京都中央区八重洲一丁目4番10号
        <br />
      </Typography>
    </DialogContent>
  }

  const paymentNoticeAndInvoice = (emailPreview: any) => {
    return <DialogContent>
      <Typography variant="body1">
        <strong>From:</strong> {emailPreview?.companyName}
        <br />
        <strong>Subject:</strong> {emailPreview?.subject}
        <br />
        <strong>To:</strong> {emailPreview?.to}
        <br />
        {emailPreview?.attachments.length > 0 && <strong>Attachment:</strong>} {
          emailPreview?.attachments?.map((name: string) => {
            return <>📎{name}{' '}</>;
          })
        }
        <br />
        ===================================
        <br />
        {emailPreview?.companyName}
        <br />
        ご担当者様
        <br />
        <br />
        平素よりお世話になっております。
        <br />
        株式会社ギフトモール Annyお祝い体験事務局でございます。
        <br />
        <br />

        掲題の件についてのご連絡です。
        <br />
        <br />
        【Annyお祝い体験】{emailPreview?.month}月度ご利用に関するお支払通知書とご請求書をお送り致します。
        内容をご確認頂けますでしょうか。
        <br />
        *別に「ご請求書」をお送りしておりますので、弊社へのお振込み額はこちらをご確認いただきますようよろしくお願いいたします。
        <br />
        <br />
        ご不明点等ございましたらお気軽にお申し付けください。
        <br />
        <br />
        どうぞよろしくお願いいたします。
        <br />
        ===================================
        <br />
        最高の"一生に一度”をあなたに
        <br />
        Annyお祝い体験｜パートナー窓口
        <br />
        https://oiwai.anny.gift/
        <br />
        東京都中央区八重洲一丁目4番10号
        <br />
        東京都中央区八重洲一丁目4番10号
        <br />
      </Typography>
    </DialogContent>
  }

  const renderContent = (emailPreview: any) => {
    switch (emailPreview.emailType) {
      case 'invoice':
        return invoiceTemplate(emailPreview);
      case 'payment':
        return paymentNotice(emailPreview);
      default:
        return paymentNoticeAndInvoice(emailPreview);
    }
  }

  return (
    <Dialog maxWidth={"md"} open={open} onClose={onClose}>
      {emailPreview?.length > 0 &&
        renderContent(emailPreview[0])}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailPreviewModal;
